export enum EServicesAvailableStatus {
  ALL_SERVICES = 'all_services',
  SALARY_ON_DEMAND = 'salary_on_demand',
  BILL_PAYMENTS = 'bill_payments',
  BENEFITS = 'benefits',
  GIFT_CARDS = 'gift_cards',
  CREDITS = 'credits',
  MONTO_CASH = 'monto_cash',
  CARDS = 'cards',
  PAYMENT_REFERENCES = 'payment_references',
  PAYMENT_PLAN = 'payment_plans',
  TRANSACTIONAL_LATENCY = 'transactional_latency',
  MONTO_CASH_TRANSACTION = 'monto_cash_transaction',
  BILL_PAYMENTS_STP = 'bill_payments_stp',
  SHOP_ORDERS = 'shop_orders',
}

export interface IServicesAvailableStatus {
  [EServicesAvailableStatus.ALL_SERVICES]: boolean
  [EServicesAvailableStatus.SALARY_ON_DEMAND]: boolean
  [EServicesAvailableStatus.BILL_PAYMENTS]: boolean
  [EServicesAvailableStatus.BENEFITS]: boolean
  [EServicesAvailableStatus.GIFT_CARDS]: boolean
  [EServicesAvailableStatus.CREDITS]: boolean
  [EServicesAvailableStatus.MONTO_CASH]: boolean
  [EServicesAvailableStatus.CARDS]: boolean
  [EServicesAvailableStatus.PAYMENT_REFERENCES]: boolean
  [EServicesAvailableStatus.PAYMENT_PLAN]: boolean
  [EServicesAvailableStatus.TRANSACTIONAL_LATENCY]: boolean
  [EServicesAvailableStatus.MONTO_CASH_TRANSACTION]: boolean
  [EServicesAvailableStatus.BILL_PAYMENTS_STP]: boolean
  [EServicesAvailableStatus.SHOP_ORDERS]: boolean
}
