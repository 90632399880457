import type { Profile } from '@modules/user/models/profile'
import { EBenefitPlanStatus } from '@modules/benefit/interfaces/benefit'
import { ETutorialType } from '@modules/guide/tutorial/interfaces/tutorial.interface'
import { EServicesAvailableStatus, IServicesAvailableStatus } from '@modules/user/models/services'

export class ProfileModel {
  public pProfile: Profile

  get accountNumber(): string {
    return this.pProfile.accountNumber
  }

  get allowPaymentCard(): boolean {
    return this.pProfile.allowPaymentCard
  }

  get accountType(): string {
    return this.pProfile.accountType
  }

  get benefitPlanSusbcription(): EBenefitPlanStatus {
    if (this.pProfile.premium_by_company) {
      return EBenefitPlanStatus.ACTIVE
    }
    return this.pProfile.benefits_status
  }

  get premiumByCompany(): boolean {
    return this.pProfile.premium_by_company
  }

  get bankName(): string {
    return this.pProfile.bankName || '-'
  }

  get cellphone(): string {
    return this.pProfile.cellphone || ''
  }

  /** TODO: Implement */
  get companyName(): string {
    return this.pProfile.company || '-'
  }

  get clabe(): string {
    return this.pProfile.clabe
  }

  get cardNumber(): string {
    return this.pProfile.cardNumber
  }

  get corporateEmail(): string {
    return this.pProfile.corporateEmail
  }

  get montoId(): string {
    return this.pProfile.montoId
  }

  get services(): IServicesAvailableStatus {
    return this.pProfile.services
  }

  get fullName(): string {
    const name = this.pProfile?.firstName?.split(' ')
    const lastName = this.pProfile?.lastName?.split(' ')
    if (name?.length > 0) {
      return `${name[0]} ${lastName[0]}`
    } else {
      return ''
    }
  }

  get name(): string {
    const name = this.pProfile?.firstName?.split(' ')
    if (name?.length > 0) {
      return name[0] //
    }
    return ''
  }

  get firstName(): string {
    return this.pProfile.firstName
  }

  get lastName(): string {
    return this.pProfile.lastName
  }

  get paymentFrecuency(): string {
    return this.pProfile.paymentFrequency || '-'
  }

  get pictureUrl(): string {
    return this.pProfile.profilePictureUrl
  }

  get userPlaceholder(): string {
    return this.pProfile.firstName?.substring(0, 1) + this.pProfile.lastName?.substring(0, 1)
  }

  /** TODO: Implement */
  get position(): string {
    return '-'
  }

  get salary(): number | string {
    return this.pProfile.salary
  }

  get activeStatus(): string {
    return this.pProfile.activeStatus
  }

  get status(): string {
    return this.pProfile.status
  }

  get pennyCheck(): boolean {
    return this.pProfile.pennyCheck
  }

  get isActive(): boolean {
    return this.pProfile.isActive
  }

  get hasInactiveService(): boolean {
    return this.pProfile.hasInactiveService
  }

  get hasMFA(): boolean {
    return this.pProfile.hasMFA
  }

  get companyId(): string {
    return this.pProfile.companyId
  }

  get id(): string {
    return this.pProfile.id
  }

  constructor(profile: Profile) {
    this.pProfile = profile
  }

  get changePassword(): boolean | null {
    return this.pProfile.change_password
  }

  get migrated(): boolean | null {
    return this.pProfile.migrated
  }

  get isCreditActive(): boolean {
    return this.pProfile.isCreditActive
  }

  get isMontoShopActive(): boolean {
    return this.pProfile.isMontoShopActive
  }

  get tutorials(): Record<ETutorialType, boolean> {
    return this.pProfile.tutorials
  }

  get minMonthsSeniorityRequired(): number {
    return this.pProfile.minMonthsSeniorityRequired
  }

  get hasRecommendedMonto(): boolean {
    return this.pProfile.hasRecommendedMonto
  }

  toJSON(): Partial<ProfileModel> {
    return {
      accountType: this.accountType,
      cellphone: this.cellphone,
      corporateEmail: this.corporateEmail,
      clabe: this.clabe,
      cardNumber: this.cardNumber,
      bankName: this.bankName,
      montoId: this.montoId,
      fullName: this.fullName,
      name: this.name,
      firstName: this.firstName,
      lastName: this.lastName,
      id: this.id,
      paymentFrecuency: this.paymentFrecuency,
      position: this.position,
      salary: this.salary,
      activeStatus: this.activeStatus,
      pennyCheck: this.pennyCheck,
      status: this.status,
      hasInactiveService: this.hasInactiveService,
      changePassword: this.changePassword,
      migrated: this.migrated,
      companyId: this.companyId,
      companyName: this.companyName,
      pictureUrl: this.pictureUrl,
      isCreditActive: this.isCreditActive,
      isMontoShopActive: this.isMontoShopActive,
      benefitPlanSusbcription: this.benefitPlanSusbcription,
      tutorials: this.tutorials,
      premiumByCompany: this.premiumByCompany,
      services: this.services,
      allowPaymentCard: this.allowPaymentCard,
      hasMFA: this.hasMFA,
      minMonthsSeniorityRequired: this.minMonthsSeniorityRequired,
      hasRecommendedMonto: this.hasRecommendedMonto,
    }
  }
}
