import { createFeatureSelector, createSelector } from '@ngrx/store'
import type { IUserState } from '../interfaces/user'
import { UserStatus } from '../interfaces/user'
import { EBenefitPlanStatus } from '@modules/benefit/interfaces/benefit'
import { UserModel } from '@modules/user/models/user.model'
import { EServicesAvailableStatus, IServicesAvailableStatus } from '@modules/user/models/services'
import { ProfileModel } from '@modules/user/models/profile.model'

export const userFeatureSelector = createFeatureSelector<IUserState>('user')

export const selectUserLoadLoading = createSelector(userFeatureSelector, state => state.loading)
export const userProfileSelector = createSelector(userFeatureSelector, state => state.user?.profile as ProfileModel)
export const selectUser = createSelector(userFeatureSelector, state => state.user as UserModel)
export const userSessionStatusSelector = createSelector(userFeatureSelector, state => state.user?.status)
export const userVerifyInfoSelector = createSelector(userFeatureSelector, state => state.verifyInfo)
export const userPennyCheckSelector = createSelector(userFeatureSelector, state => state.user?.profile?.pennyCheck)
export const userBalanceSelector = createSelector(userFeatureSelector, state => {
  return {
    balance: state.balance,
    balanceFuture: state.balanceFuture,
  }
})
export const showPennyCheckAlertSelector = createSelector(
  userFeatureSelector,
  state => !state.user?.profile?.pennyCheck
)
export const employeeActiveSelector = createSelector(
  userFeatureSelector,
  state =>
    state.user?.hasCompany &&
    [UserStatus.ACTIVE].includes(state.user?.profile?.activeStatus as UserStatus) &&
    state.user?.profile?.status === UserStatus.ACTIVE
)

export const servicesAvailableSelector = createSelector(
  userFeatureSelector,
  state => state.user?.profile?.services as IServicesAvailableStatus
)

export const servicesAvailableCardsSelector = createSelector(userFeatureSelector, state => {
  return (
    state.user?.profile?.services[EServicesAvailableStatus.CARDS] &&
    state.user?.profile?.allowPaymentCard &&
    ((!state.user?.hasCompany &&
      [UserStatus.VALIDATED, UserStatus.ACTIVE].includes(state.user?.profile?.status as UserStatus)) ||
      state.user?.hasCompany)
  )
})

export const servicesAvailablePaymentPlanSelector = createSelector(
  userFeatureSelector,
  state => state.user?.profile?.services[EServicesAvailableStatus.PAYMENT_PLAN]
)

export const servicesAvailableCreditSelector = createSelector(
  userFeatureSelector,
  state => state.user?.profile?.services[EServicesAvailableStatus.CREDITS]
)

export const servicesAvailableBillPaymentsSTPSelector = createSelector(
  userFeatureSelector,
  state => state.user?.profile?.services[EServicesAvailableStatus.BILL_PAYMENTS_STP]
)

export const employeeActiveSalarySelector = createSelector(userFeatureSelector, state => {
  return (
    state.user?.hasCompany &&
    [UserStatus.ACTIVE, UserStatus.SUSPENDED].includes(state.user?.profile?.activeStatus as UserStatus) &&
    state.user?.profile?.status === UserStatus.ACTIVE
  )
})

export const selectProfileTutorials = createSelector(userFeatureSelector, state => state.user?.profile?.tutorials)
export const selectBenefitSusbcriptionActive = createSelector(userFeatureSelector, state =>
  state.user?.profile?.benefitPlanSusbcription
    ? [EBenefitPlanStatus.ACTIVE, EBenefitPlanStatus.CANCELED].includes(
        state.user?.profile?.benefitPlanSusbcription as EBenefitPlanStatus
      )
    : (false as boolean)
)

export const selectShowValidateInfoRibbon = createSelector(
  userFeatureSelector,
  state =>
    (state.user && [UserStatus.PENDING_ADMIN_VALIDATION].includes(state.user?.profile?.activeStatus as UserStatus)) ||
    (state.user?.profile?.pennyCheck === null &&
      [UserStatus.ACTIVE].includes(state.user?.profile?.activeStatus as UserStatus))
)
