import { AfterContentChecked, AfterViewInit, inject, OnDestroy, OnInit } from '@angular/core'
import { ChangeDetectorRef, Component, NgZone } from '@angular/core'
import { NavigationEnd, Router, RouterModule } from '@angular/router'
import { environment } from '@environments/environment'
/* import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx' */
import { ModalController, NavController } from '@ionic/angular'
import type { IConfig } from '@modules/config/interfaces/config'
import type { IUserState } from '@modules/user/interfaces/user'
import { UserStatus } from '@modules/user/interfaces/user'
import {
  selectUser,
  servicesAvailableSelector,
  userSessionStatusSelector,
} from '@modules/user/selectors/user.selectors'
import { Store } from '@ngrx/store'
import { SplitPaneService } from '@services/split-pane.service'
import { UpdateAppService } from '@services/update-app.service'
import type { Observable } from 'rxjs'
import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { ContainerService } from './containers/container.service'
import posthog from 'posthog-js'
import { LogoutService } from '@services/logout.service'
import { ChatbotService } from '@services/chatbot.service'
import { ClarityService } from '@services/clarity.service'
import { RedeemRequestCodeComponent } from '@modules/redeem/components/request-code/redeem-request-code.component'
import { UserModel } from '@modules/user/models/user.model'
import { ConfigModule } from '@modules/config/config.module'
import { NotificationModule } from '@modules/notification/notification.module'
import { IconModule } from '@components/atoms/icon/icon.module'
import { PushPipe, LetDirective } from '@ngrx/component'
import { JsonPipe, NgClass, NgIf } from '@angular/common'
import { addIcons } from 'ionicons'
import {
  addCircleOutline,
  close,
  closeOutline,
  folderOpenOutline,
  logOutOutline,
  removeCircleOutline,
} from 'ionicons/icons'
import { register } from 'swiper/element/bundle'
import {
  IonApp,
  IonBadge,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterLink,
  IonRouterLinkWithHref,
  IonRouterOutlet,
  IonSplitPane,
  IonToolbar,
} from '@ionic/angular/standalone'
import { TextZoom } from '@capacitor/text-zoom'
import { Capacitor } from '@capacitor/core'
import { UserInfoCardComponent } from '@modules/user/components/user-info-card/user-info-card.component'
import { AppStore } from '@modules/app/app.store'
import { EServicesAvailableStatus } from '@modules/user/models/services'

register()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    JsonPipe,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonMenuToggle,
    IonIcon,
    IonContent,
    IonList,
    IonApp,
    IonSplitPane,
    IonLabel,
    IonRouterOutlet,
    IonItem,
    IonRouterLink,
    IonRouterLinkWithHref,
    RouterModule,
    NotificationModule,
    UserInfoCardComponent,
    ConfigModule,
    IconModule,
    LetDirective,
    PushPipe,
    NgClass,
    NgIf,
    IonBadge,
  ],
})
export class AppComponent extends ContainerService implements OnInit, OnDestroy, AfterContentChecked {
  environment = environment
  url = ''
  public config$
  private unsubscribeAll = new Subject()
  readonly services$ = this._store.select(servicesAvailableSelector)
  readonly EServicesAvailableStatus = EServicesAvailableStatus

  userSessionStatus$: Observable<UserStatus>
  UserStatus = UserStatus

  _modalController = inject(ModalController)
  user$ = this._store.select(selectUser)
  readonly appStore = inject(AppStore)

  constructor(
    public splitPaneService: SplitPaneService,
    protected override navController: NavController,
    protected override zone: NgZone,
    protected _logoutService: LogoutService,
    private cdr: ChangeDetectorRef,
    protected override _router: Router,
    /* protected _firebaseAnalytics: FirebaseAnalytics, */
    private _store: Store<{ config: IConfig; user: IUserState }>,
    private _updateAppService: UpdateAppService,
    private _clarityService: ClarityService,
    private _chatbotService: ChatbotService
  ) {
    super()
    addIcons({ logOutOutline, closeOutline, close, addCircleOutline, folderOpenOutline, removeCircleOutline })
    this.config$ = this._store.select('config')
    this.userSessionStatus$ = this._store.select(userSessionStatusSelector)

    _router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.url = location.pathname
      posthog.capture('$pageview')
    })
    if (Capacitor.isNativePlatform()) {
      TextZoom.set({
        value: 1,
      })
    }

    this._chatbotService.init()

    if (environment.posthogKey && environment.production) {
      posthog.init(environment.posthogKey, {
        api_host: 'https://app.posthog.com',
        debug: !environment.production,
      })
    }
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this._updateAppService.init()
    this._clarityService.init()
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges()
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(true)
    this.unsubscribeAll.complete()
  }

  logout() {
    this._logoutService.logOut()
  }

  async onOpenModalRedeem() {
    const modal = await this._modalController.create({
      component: RedeemRequestCodeComponent,
      cssClass: 'modal-dialog',
    })

    return await modal.present()
  }
}
